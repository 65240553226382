// React
import React, { useState, useEffect } from 'react';

// Gatsby

// Emotion / styling
import s from 'src/styles';

// Packages

// Utilities
import { useTheme, useCart } from 'hooks';

// Components
import { PageNavigation } from 'components/pageNavigation';
import PageIntroduction from 'components/pageIntroduction';
import Button from 'components/button';

const ConfirmPaymentPageComponent = ({ pageContext, location }) => {
    const { labels } = pageContext;

    // State: Loading
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(false);
    const [confirmId, setConfirmId] = useState(null);

    // Hook: Account
    const { confirmPayment } = useCart();

    // Set theme
    const theme = useTheme(s.color('white'));

    // Function: Confirm
    function onConfirm() {
        setLoading(true);
        setError(false);
        if (confirmId) {
            confirmPayment(confirmId)
                .then(() => {
                    setLoading(false);
                    setConfirmed(true);
                })
                .catch(() => {
                    setLoading(false);
                    setError(true);
                });
        }
    }

    // Effect: Set recover key based on location
    useEffect(() => {
        if (location.search) {
            setConfirmId(location.search.replace('?payment=', ''));
        }
    }, [location]);

    return (
        <>
            <s.layout.PageContainer>
                <div className=" pt-100 lg:pt-140">
                    <PageNavigation {...{ theme }}>
                        <span>{labels.PAGES.CONFIRM_PAYMENT.HEADING}</span>
                    </PageNavigation>
                    <PageIntroduction
                        {...{
                            content: labels.PAGES.CONFIRM_PAYMENT.INTRODUCTION,
                        }}
                    />
                </div>
                <div className="flex flex-col items-start mt-12 space-y-12">
                    <Button
                        {...{
                            disabled: confirmed,
                            fullyDisalbed: confirmed,
                            loading,
                            theme,
                            onClick() {
                                onConfirm();
                            },
                            label: confirmed
                                ? labels.PAGES.CONFIRM_PAYMENT.CONFIRM_COMPLETE
                                : labels.PAGES.CONFIRM_PAYMENT.BUTTON,
                            feedback: error
                                ? 'noooo'
                                : labels.PAGES.CONFIRM_PAYMENT.CONFIRM_COMPLETE,
                        }}
                    />
                    {error && (
                        <span className="mt-8 t-h18">
                            {labels.PAGES.CONFIRM_PAYMENT.NOT_FOUND}
                        </span>
                    )}
                </div>
            </s.layout.PageContainer>
        </>
    );
};

const NamedConfirmPaymentPageComponent = props => {
    return <ConfirmPaymentPageComponent {...props} />;
};

export default NamedConfirmPaymentPageComponent;
